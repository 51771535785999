<template>
    <div class="answer">
      <Empty title="暂无疑难" v-if="records.length === 0" />
      <div class="head">
        <div class="nav">
          <p :class="type == 'week' && 'active'" @click="changeType('week')">本周疑难</p>
          <p :class="type == 'history' && 'active'" @click="changeType('history')">历史疑难</p>
          <p :class="type == 'all' && 'active'" @click="changeType('all')">上传记录</p>
        </div>
        <div class="upLoad" @click="upWork"></div>
      </div>
      <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div
          @click="jumpDetail(it)"
          tag="div"
          class="list"
          v-for="it in records"
          :key="it.id"
          v-show="!it.del"
        >
          <div class="head1">
            <img class="misImg" src="../../image/task/mistakes.png" />
            <p>{{it.courseName}}</p>
            <i class="close" v-show="it.status !=1" @click="delCourse(it, $event)"/>
          </div>
          <p class="time">批改状态：{{showStatus(it.status)}}</p>
          <p class="time" v-show="it.status == 3">驳回理由：{{it.reason}}</p>
          <p class="time">上传时间：{{it.gmtCreate}}</p>
        </div>
      </div>
      <Tips :tip="msg" />
      <Confirm :tip.sync="title" :callback="sureCourse" />
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';
import Empty from '@/components/Empty';
export default {
  name: 'Home',
  components: {
    Empty
  },
  filters: {
    capitalize: function (value) {
      if (!value) return 0
      return value.toFixed(2)
    }
  },
  data () {
      return {
        type: 'week',
        records: [],
        busy: false,
        current: 1,
        size: 10,
        msg: '',
        title: '',
        item: ''
      }
  },
  mounted() {
    
  },
  methods: {
    jumpDetail(it) {
      // "status":"int //状态：0待批改 1批改中 2批改完成 3驳回",
      if (it.status === 1) {
        return;
      }
      this.$router.push(`/answerDetail/${it.id}`);
    },
    showStatus(status) {
      // "status":"int //状态：0待批改 1批改中 2批改完成 3驳回",
      if (status === 0) {
        return '待批改'
      }
      if (status === 1) {
        return '批改中'
      }
      if (status === 2) {
        return '批改完成'
      }
      if (status === 3) {
        return '驳回'
      }
    },
    delCourse(item, $event) {
      this.title = '确定删除该作业？';
      this.item = item;
      $event.preventDefault();
      $event.stopPropagation();
    },
    sureCourse() {
      this.$http.get(`api/courseWork/delete/${this.item.id}`, {
      })
      .then((res) => {
        this.title = '';
        this.msg = '删除成功';
        this.item.del = true;
        this.$forceUpdate();
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    upWork() {
      this.$router.push(`/uploadWork`)
    },
    changeType(type) {
      this.type = type;
      this.records = [];
      this.busy = false;
      this.current = 1;
      this.loadMore();
    },
    loadMore() {
      this.$http.post('/api/courseWork/pageList', {
        size: this.size,
        current: this.current,
        type: this.type
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        data.records = data.records.map((it) => {
          it.workImage = JSON.parse(it.workImage)
          return it;
        })
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .answer {
    min-height: 100vh;
    background-color: #f4f6f6;
    .head {
      height: 1rem;
      background-color: #79cdca;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .nav {
        background-color: #8bcbc7;
        display: flex;
        width: 4.6rem;
        color: white;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.3rem;
        border: 1px solid white;
        border-radius: 0.7rem;
        align-items: center;
        box-sizing: border-box;
        p {
          height: 100%;
          border-radius: 0.7rem;
          text-align: center;
          flex: 1;
          cursor: pointer;
          &.active {
            background-color: white;
            color: #8bcbc7;
          }
        }
      }
      .upLoad {
        position: absolute;
        right: 0.3rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.45rem;
        height: 0.45rem;
        background-image: url('../../image/first/upload.png');
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .loadMore {
      max-height: calc(100vh - 1rem);
      overflow: scroll;
      padding-bottom: 1rem;
    }
    .list {
      padding: 0.3rem;
      box-sizing: border-box;
      background-color: white;
      margin-bottom: 0.25rem;
      cursor: pointer;
      .head1 {
        display: flex;
        position: relative;
        .close {
          position: absolute;
          right: 0.3rem;
          top: 50%;
          transform: translateY(-50%);
          background-image: url('../../image/task/close.png');
          background-size: 100% 100%;
          width: 0.32rem;
          height: 0.32rem;
          z-index: 10;
        }
        .misImg {
          width: 0.45rem;
          height: 0.45rem;
        }
        p {
          font-size: 0.32rem;
          margin-left: 0.2rem;
          width: 5.5rem;
        }
      }
      .time {
        color: #999999;
        margin-top: 0.1rem;
        font-size: 0.26rem;
      }
      .cn {
        font-size: 0.32rem;
        margin-top: 0.15rem;
      }
    }
  }
</style>
